import { constructTransactionRequestV1 } from "../requestConstruction/DynamicReqConstruction";
import { AxiosInstance, commonProductAxiosInstance,GetCommonEventName,getServiceRequestDetail } from "./API";
import * as SaveInsuredDetails from './SaveInsuredAPI';
import store from "../../../../store";
import appActions from "../../../../actions";
import {  clearSubappData,getInsured } from "./CommonUtils";
import { Toast, triggerInfoToast } from "./HelperUtils";
import { attributeSplit } from "./MetaUtils";
import { updateProdNav } from "./Navigation_Util";
import { constructEncodedString, decodeString, dynamicObjectConstruction } from "./NoCodeUtils";
import * as Action from "../../../../actions/types";
import { EntitySubAppTranslationV2,  getEntitySubAppMappingV2 } from "../requestConstruction/ResponseTranslation";


const  {saveInsured_Homeowners,getInsured_Homeowners, getAgencyAgent, getInsured_CommercialLines}  = SaveInsuredDetails
const { actionType } = appActions;


const  CreateSubmissionorUpdate =async (state,props,pageDetail,productInfo,lobUtil)=>{
     props.metadataReducer.submissionResponse.SubmissionID?UpdateSubmission(state,props,pageDetail,productInfo,lobUtil):CreateSubmission(state,props,pageDetail,productInfo,lobUtil)
}
const CreateSubmission =async (state,props,pageDetail,productInfo,lobUtil)=>{
    let { currentPageIndex } = props.UIFlowReducer;
    props.showLoader();
    props.setSubmissionView("createsubmission");
    let createSubmissionRequest = await constructCreateSubmissionRequest(state,props,pageDetail,productInfo,lobUtil);
    console.log("createSubmissionRequest", createSubmissionRequest);
    await commonProductAxiosInstance
      .post("/core/invokeWorkflowEvent/V4", createSubmissionRequest)
      .then((response) => {
        props.hideLoader();
        state.createSubmissionRes = Object.assign(response);
        props.setCreateSubmissionResponse(response.data);
        props.setSubmissionID(response.data.SubmissionID);
        // if(state.pagelevelValues.SelectedLineOfBusiness && state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true')
        // this.setParentTabEnable();
        if(productInfo[currentPageIndex - 1].IncludeForwardNav)
        {
          props.solartisUIPageNavigationStatus(productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus);
          lobUtil.navigationStatus && lobUtil.navigationStatus(productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus)
        }
      })
      .catch((err) => {
        props.hideLoader();
        console.log(err);
      });
  }
const constructCreateSubmissionRequest = async(state,props,pageDetail,productInfo,lobUtil) => {
    let createSubmissionRequest = Object.create({});
    createSubmissionRequest["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
    );
    let translatedrequest = await constructTransactionRequestV1(state,props,state.pagelevelValues,"SolartisCommonMaster_WF",true,false,"V4")

    createSubmissionRequest = Object.assign({}, translatedrequest , createSubmissionRequest, {
      OwnerId: sessionStorage.getItem("OwnerID"),
      EventName: "SolartisCommonMaster_WF",
      TargetEventName: pageDetail.createSubmissionEventName,
      EventAction:pageDetail.createSubmissionEventAction,
      Account:state.pagelevelValues['Account'],
      PolicyInfo:state.pagelevelValues['PolicyInfo']
    });
    if(state.pagelevelValues.hasOwnProperty("SelectedLineOfBusiness"))
    {
      Object.entries(state.pagelevelValues.SelectedLineOfBusiness).forEach(([keyName, value]) => {
        if (keyName === 'LOBName') {
          let SelectedLOB = [];
          let LOBList;
          if(!(state.pagelevelValues["SelectedLineOfBusiness"]["IsMultiLob"] === "true")){
            LOBList = state.pagelevelValues["SelectedLineOfBusiness"][keyName].split(",");
          }else{
            LOBList = state.pagelevelValues["SelectedLineOfBusiness"]["LOBName"];
          }
          LOBList.map((entry) => {
            var singleObj = {};
            singleObj["LOBName"] = entry;
            SelectedLOB.push(singleObj);
          });

          createSubmissionRequest = {
            ...createSubmissionRequest,
            "SelectedLineOfBusiness": SelectedLOB,
          };
          createSubmissionRequest["SelectedLineOfBusiness"]=
          createSubmissionRequest["SelectedLineOfBusiness"].map((val)=>{
            if(!val.IncludeLOBInRating)
              val.IncludeLOBInRating='Yes'
            return val})
          }
      })
    }

    return createSubmissionRequest;
  };
const constructUpdateSubmissionRequest = async (state,props,pageDetail,productInfo,lobUtil) => {
    let updateSubmissionRequest = await constructCreateSubmissionRequest(state,props,pageDetail,productInfo,lobUtil);
    delete updateSubmissionRequest.Insured;

    const SubmissionID = props.UIFlowReducer.submissionID;
    updateSubmissionRequest = Object.assign({}, updateSubmissionRequest, {
      TargetEventName: pageDetail.updateSubmissionEventName,
      EventAction: pageDetail.updateSubmissionEventAction,
      SubmissionNumber:props.metadataReducer.submissionResponse.SubmissionNumber,
      SubmissionID: SubmissionID,
      ROOTID: SubmissionID,
      PARENTID: SubmissionID,
      ID: SubmissionID,
    });

    updateSubmissionRequest["SelectedLineOfBusiness"] = updateSubmissionRequest["SelectedLineOfBusiness"].map( lob => {
        props.metadataReducer.submissionResponse.SelectedLineOfBusiness.forEach( rlob => {
                if(rlob.LOBName === lob.LOBName){
                  lob = rlob;
                  return ;
                }
              });
        return lob;
      });
    updateSubmissionRequest["SelectedLineOfBusiness"]=updateSubmissionRequest["SelectedLineOfBusiness"].map((val)=>{
    if(!val.IncludeLOBInRating)
      val.IncludeLOBInRating='Yes'
    return val})
    return updateSubmissionRequest;
  };

const UpdateSubmission =async (state,props,pageDetail,productInfo,lobUtil)=> {
    props.showLoader();
    let { currentPageIndex } = props.UIFlowReducer;
    props.setSubmissionView("createsubmission");
    let LOB = props.metadataReducer.lob
    console.log("LOB->",LOB)
    let updateSubmissionRequest = await constructUpdateSubmissionRequest(state,props,pageDetail,productInfo,lobUtil);
    await commonProductAxiosInstance
      .post("/core/invokeWorkflowEvent/V4", updateSubmissionRequest)
      .then((response) => {
        props.hideLoader();
        props.setCreateSubmissionResponse(response.data);
        // if(state.pagelevelValues.SelectedLineOfBusiness && state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true')
        //   this.setParentTabEnable();
        if(productInfo[currentPageIndex - 1].IncludeForwardNav)
        {
          props.solartisUIPageNavigationStatus(productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus);
          lobUtil.navigationStatus && lobUtil.navigationStatus(productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus)
        }
        let additionalCoverageVisibility = props.metadataReducer.additionalCoverageVisibility;
        if(response.data[LOB]){
          if (response.data[LOB].Policy.length > 1) {
            const primaryStateInfo = response.data[LOB].Policy.find(state => state.SolartisIsPrimaryState === 'Yes');
            props.setprimaryState(primaryStateInfo.State);
            additionalCoverageVisibility[LOB] = "true";
            props.setAdditionalCoverageVisibility(
              additionalCoverageVisibility
            );
          }
          else {
            if(response.data[LOB].Policy[0].State){
            props.setprimaryState(response.data[LOB].Policy[0].State);
              additionalCoverageVisibility[LOB] = "true";
              props.setAdditionalCoverageVisibility(
                additionalCoverageVisibility
              );
            }
          }
        }
      })
      .catch((err) => {
        props.hideLoader();
        console.log(err);
      });
  }
  const saveAccountInsured=async(state,props,pageDetail,value,lobUtil,UpdateState)=>{
    let submissionResponse=  await saveInsured_Homeowners(state,props,pageDetail,UpdateState);
     if(pageDetail.updateProductNavigationNeeded){
       let reduxState = store.getState();
       if(submissionResponse.data && submissionResponse.data[pageDetail.brokerageKey]) {
         let splitParams = attributeSplit(pageDetail.splitFlowKey)
         if(reduxState.metadataReducer.Flow !==splitParams[submissionResponse.data[pageDetail.brokerageKey]] ){
             store.dispatch({ type: actionType.SET_FLOW ,payload : splitParams[submissionResponse.data[pageDetail.brokerageKey]] });
          await updateProdNav(props,pageDetail)
         }}
      }
   return;
 }
  const saveAccountInsured_RateIndication=async(state,props,pageDetail,value,lobUtil,UpdateState)=>{
    const SaveInsured= await SaveInsuredDetails[props.metadataReducer.lob].saveSubmission_Homeowners_RateIndication(state,props,pageDetail,UpdateState);
    return;
  }
  const saveAccountInsuredMPL=async(state,props,pageDetail,value,lobUtil,UpdateState)=>{
    const SaveInsured= await SaveInsuredDetails[props.metadataReducer.lob].saveInsured_Mpl(state,props,pageDetail,UpdateState);
    return;
  }
  const saveAccountInsuredCL=async(state,props,pageDetail,value,lobUtil,UpdateState)=>{
    const SaveInsured= await SaveInsuredDetails[props.metadataReducer.lob].saveInsured_CL(state,props,pageDetail,UpdateState);
    return;
  }
  const saveUnderwritingQuestions= async (state,props,pageDetail,productInfo,lobUtil)=>{
    console.log("hhhhhhhhhhhhhhh",props.metadataReducer.lob)
   let UtilFile = false
    const UWid = UtilFile && await UtilFile[props.metadataReducer.lob].saveUnderwritingQuestions(props, state, pageDetail)
    props.setUndewritingQuestionsID(UWid);
  }

  const UpdateNavigation= async(state,props,pageDetail,productInfo,lobUtil)=>{
    let productPage;
    if(state.pagelevelValues.SelectedLineOfBusiness && state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === "true"){
      productPage = props.navigation.TabNavigation[state.parentTabPosition].ChildrenTabNavigation ? props.navigation.TabNavigation[state.parentTabPosition].ChildrenTabNavigation[state.tabPosition].Details :   props.navigation.TabNavigation[state.parentTabPosition].Details;
    }else{
      productPage = props.navigation.productNavigation[state.pagelevelValues["SelectedLineOfBusiness"]["LOBName"]];
    }
    let pageNavigation = productPage;
    updatePageNavigation(state,props,pageNavigation);
  }

  const updatePageNavigation = async (state,props,pageNavigation) => {

    if(state.pagelevelValues.SelectedLineOfBusiness && state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true'){
      await props.setPageNavigation(pageNavigation);
    }else{
      let pageNav = Object.assign({}, props.navigation);
      pageNav.pageNavigation[2].children[2].children = pageNavigation;
      await Promise.all([props.setNavigation(pageNav), props.setPageNavigation(pageNavigation)]);
      return
    }
  };
  const existingInsured=async(state,props,func)=>{
    const getInsured=await getInsured_Homeowners(state,props,func);
    props.setexpandPanelData(true)
}


const componentDidMount_MPL = async(state, props, UpdateState) =>{
  try{
    UpdateState('loading',true,true);
  props.showLoader();
  let transactionPrimaryObject = state.transactionPrimaryObject || {}
  if (props.metadataReducer.FlowType=="Find" || props.UIFlowReducer.insuredID){
    let EventName=GetCommonEventName();
    const currentPageIndex  = state.currentPageIndex;
    let clearData=props.pageNavigation[currentPageIndex].clearData;
    clearSubappData(clearData,state.pagelevelValues,state.transactionPrimaryObject,UpdateState)
  let [{response, responseFromTranslation}, agentResponse] = await Promise.all([getInsured(state,props,UpdateState,props.UIFlowReducer.insuredID, EventName), getAgencyAgent(state,props,UpdateState)]);
  let {AdditionalInsuredDetail} = response.data
  props.setAgencyDetails(agentResponse.Agency);
  props.setAgentDetails(agentResponse.Agent);
    UpdateState("SelectedAddnlInsured_dataList", AdditionalInsuredDetail , false);
    UpdateState("pagelevelValues",{...state.pagelevelValues,...responseFromTranslation},true);
    transactionPrimaryObject = {InsuredSearchMPL: {
      ExistingInsured: "Yes"
    }};
    store.dispatch({type: actionType.FINDINSURED_CLICK, payload:false});
    UpdateState("transactionPrimaryObject",{...state.transactionPrimaryObject, ...responseFromTranslation,  transactionPrimaryObject},true);
  }
  props.setexpandPanelData(true)
  UpdateState('loading',false,true);
  return {transactionPrimaryObject}
}
catch(error){
  console.log("Error in componentDidMount_MPL", error)
}
}



const componentDidMount_AgencyAgent_MPL = async(state, props, UpdateState)=>{
  UpdateState('loading',true,true);
  try{
    props.showLoader();
    if (props.UIFlowReducer.agentID || props.metadataReducer.AgentInformation.ID){
      let  agentResponse = await getAgencyAgent(state,props,UpdateState);
      props.setAgencyDetails(agentResponse.Agency);
      props.setAgentDetails(agentResponse.Agent);
      }
    return
  }
  catch(error){
    console.log("Error in componentDidUpdate_AgencyAgent_MPL", error)
  }
  finally{
    UpdateState('loading',false,true);
  }
}

const componentDidMount_HomeOwners = async(state, props, UpdateState)=>{
  try{
      UpdateState('loading',true,true);
      let insuredResponse={},agentResponse={};
      let reduxState = store.getState();
      if(props.UIFlowReducer.pageData && Object.keys(props.UIFlowReducer.pageData).length>0){
        if(props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.pageName && props.UIFlowReducer.pageData.pageName==="Agency & Agent")
          agentResponse = await getAgencyAgent(state,props,UpdateState)
        else if(props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.pageName && props.UIFlowReducer.pageData.pageName==="Insured Details")
        [insuredResponse, agentResponse] = await Promise.all([getInsured(state,props,UpdateState,props.UIFlowReducer.insuredID,props.UIFlowReducer,undefined,false), getAgencyAgent(state,props,UpdateState)]);
        if(!agentResponse){
          UpdateState('loading',false,true);
          return;
        }
        else{
          props.setAgencyDetails(agentResponse.Agency);
        props.setAgentDetails(agentResponse.Agent);
        }
      }else{
        let [insuredResponse, agentResponse] = await Promise.all([getInsured(state,props,UpdateState,props.UIFlowReducer.insuredID,props.UIFlowReducer), getAgencyAgent(state,props,UpdateState)]);
        if(!agentResponse)
        return;
        let{SubmissionNumber}=props.UIFlowReducer;
        props.setAgencyDetails(agentResponse.Agency);
        props.setAgentDetails(agentResponse.Agent);
        if(insuredResponse!==undefined){
          let obj={...state.pagelevelValues,...agentResponse,...insuredResponse.responseFromTranslation}
          if(SubmissionNumber){
            obj['SubmissionNumber']=SubmissionNumber
          }
          UpdateState("pagelevelValues",{...obj},false)
          UpdateState("transactionPrimaryObject",{...obj},false)
        }
      }
      if(props.pageInfo.onLoadURLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
        let val=window.location.hash.split('/'),pageData={},redirectRoute='',obj={};
        if(val.length > 1 && val[2]){
            let newVal=val[2];
            pageData = decodeString(newVal);
        }
        obj = await dynamicObjectConstruction(props.pageInfo.onLoadURLInfo,{...agentResponse},obj,'response');
        pageData={...pageData,...obj}
        const encodedString= await constructEncodedString(pageData); 
        redirectRoute+=`/${val[1]}/${encodedString}`;
        props.history.push(redirectRoute);
      }
      UpdateState('loading',false,false);
      props.setexpandPanelData(true)
  }
  catch(error){
    UpdateState('loading',false,true);
    console.log("Error in componentDidMount_HomeOwners", error)
  }
}

const componentDidUpdate_HomeOwners_Insured=async(state,props,UpdateState)=>{
  if(!props.UIFlowReducer.insuredID){
    await store.dispatch({ type: actionType.SET_EXPANDPANEL_DATA, payload: true });
    return
  }
  UpdateState('loading',true,true)
  await getSubmissionData(props,state,UpdateState)
  UpdateState('loading',false,true)
}

const componentDidMount_HomeOwners_Insured = async(state, props, UpdateState)=>{
  try{
      UpdateState('loading',true,true);
      let insuredResponse={},agentResponse={};
      let reduxState = store.getState();
      if(props.UIFlowReducer.pageData && Object.keys(props.UIFlowReducer.pageData).length>0){
        if(props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.pageName && props.UIFlowReducer.pageData.pageName==="Agency & Agent")
          agentResponse = await getAgencyAgent(state,props,UpdateState)
        else if(props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.pageName && props.UIFlowReducer.pageData.pageName==="Insured Details")
        [insuredResponse, agentResponse] = await Promise.all([getInsured(state,props,UpdateState,props.UIFlowReducer.insuredID,props.UIFlowReducer,undefined,false), getAgencyAgent(state,props,UpdateState)]);
        if(!agentResponse){
          UpdateState('loading',false,true);
          return;
        }
      }else{
        await getSubmissionData(props,state,UpdateState)
      }
      if(props.pageInfo.onLoadURLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
        let val=window.location.hash.split('/'),pageData={},redirectRoute='',obj={};
        if(val.length > 1 && val[2]){
            let newVal=val[2];
            pageData = decodeString(newVal);
        }
        obj = await dynamicObjectConstruction(props.pageInfo.onLoadURLInfo,{...agentResponse},obj,'response');
        pageData={...pageData,...obj}
        const encodedString= await constructEncodedString(pageData); 
        redirectRoute+=`/${val[1]}/${encodedString}`;
        props.history.push(redirectRoute);
      }
      UpdateState('loading',false,true);
  }
  catch(error){
    UpdateState('loading',false,true);
    console.log("Error in componentDidMount_HomeOwners", error)
  }
}

const getSubmissionData = async (props, state, UpdateState) => {
  try {
    let redux = store.getState();
    let { navigationReducer } = redux;
    const currentPageIndex = redux.UIFlowReducer.currentPageIndex;
    let subApplication = navigationReducer.pageNavigation[currentPageIndex - 1].SubApplicationName;
    let { ObjectId: objectId, ObjectVerId: objectVerId } = navigationReducer.pageNavigation[currentPageIndex - 1];
    let responseFromTranslation = {};
    let [response, Entities] = await Promise.all([getSubmissionApi(state, props, UpdateState), await getEntitySubAppMappingV2({subappName:subApplication, objectId, objectVerId, state:"CW"}, "V2")])
    let { SubmissionNumber } = props.UIFlowReducer;
    if (response && response.data) {
    responseFromTranslation = await EntitySubAppTranslationV2({ ...response.data }, { SubApplicationEntity:Entities});
      let obj = { ...state.pagelevelValues, ...responseFromTranslation }
      if (SubmissionNumber) {
        obj['SubmissionNumber'] = SubmissionNumber
      }
      await UpdateState("pagelevelValues", { ...obj }, false);
      await UpdateState("transactionPrimaryObject", { ...obj }, false);
      if( (props.metadataReducer.AgentInformation === undefined ) || (props.metadataReducer.AgentInformation.ID === response.data.AgentInformation.ID)){
        props.setAgencyDetails(response.data.AgencyInformation);
        props.setAgentDetails(response.data.AgentInformation);
       }
      if (response.data && response.data.InsuredID)
        await store.dispatch({ type: actionType.SET_INSUREDID, payload: response.data.InsuredID });
      if (response.data && response.data.SubmissionNumber)
        await store.dispatch({ type: actionType.SET_SUBMISSION_NUMBER, payload: response.data.SubmissionNumber });
      if (response.data && response.data.Insured && response.data.Insured.InsuredName)
        await store.dispatch({ type: Action.SET_INSUREDNAME, payload: response.data.Insured.InsuredName });
      if (response.data.ProcessStatus)
        await store.dispatch({ type: Action.SET_PROCESS_STATUS, payload: response.data.ProcessStatus });
      if (response.data && response.data.SubmissionStatus)
        await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });
      if (response.data && response.data.AgentId)
        await store.dispatch({ type: actionType.SET_AGENTID,payload: response.data.AgentId});
      if (response.data && response.data.AgentId)
        await store.dispatch({ type: actionType.SET_AGENCYID, payload: response.data.AgencyId })
      if (response.data && response.data.Location)
        await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"RiskAddress": response.data.Location[0]} });
      if (response.data && response.data.SubmissionType)
        await store.dispatch({type: Action.SET_SUBMISSION_TYPE, payload: response.data.SubmissionType})
      await store.dispatch({ type: actionType.SET_EXPANDPANEL_DATA, payload: true });
      await store.dispatch({ type: actionType.SET_PAGEHEADER_UPDATE, payload: true });
    }
  }
  catch (error) {
    console.log("Error in getSubmissionData", error)
  }
}

const getSubmissionApi = async(state, props, UpdateState)=>{
  try{
    let redux = store.getState();
    let index = redux.UIFlowReducer.currentPageIndex
    let pageInfo = props.pageNavigation[index - 1]
    let request = {}, response;
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    request["ID"] = redux.UIFlowReducer.submissionID
    request["PARENTID"] = redux.UIFlowReducer.submissionID
    request["ROOTID"] = redux.UIFlowReducer.submissionID
    request["EntityName"] = pageInfo.Location
    request["EventName"] = pageInfo.EventName
    request["TargetEventName"] = pageInfo.getSubmissioneventName
    response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
    return response
  }
  catch(error){
    console.log("Error in getSubmissionApi", error)
  }
}

const existingInsured_CL=async(state,props,func)=>{
  const getInsured=await SaveInsuredDetails[props.metadataReducer.lob].getInsured_CL(state,props,func);
  props.setexpandPanelData(true)
}


const componentDidMount_CL = async(state, props, UpdateState)=>{
  try{
    props.showLoader();
    if (props.metadataReducer.FlowType=="Find"){
      let [insuredResponse, agentResponse] = await Promise.all([getInsured_CommercialLines(state,props,UpdateState,props.UIFlowReducer.insuredID), getAgencyAgent(state,props,UpdateState)]);
      props.setAgencyDetails(agentResponse.Agency);
      props.setAgentDetails(agentResponse.Agent);
      props.setexpandPanelData(true)
    }
  }
  catch(error){
    console.log("Error in componentDidMount_CL", error)
  }
}

const saveCoverageSelectionMPL=async(state,props)=>{
  try{
  let coverages = state.SelectedCoverageSelectionOption_datalist
  let CoverageSelected = false;
  coverages.forEach(coverage => {
    if(!CoverageSelected){
      CoverageSelected = Object.values(coverage).some(i=>i.Select === 'Yes')
      }
  })
  if(!CoverageSelected){
    triggerInfoToast("Please Select any Coverages",  { position: Toast.POSITION.TOP_RIGHT})
    throw new Error
  }
  const currentPageIndex  = props.UIFlowReducer.currentPageIndex;
  let request = Object.create({});
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName=GetCommonEventName();
  request.TargetEventName= props.pageNavigation[currentPageIndex-1].saveeventName;
  let subAppName= props.pageNavigation[currentPageIndex-1].template[0].subApplicationName;
  subAppName.forEach(subapp=>{
    if(state.pagelevelValues[subapp]){
      request[subapp] = {...state.pagelevelValues[subapp]}
    }
  });
  request.ID = props.UIFlowReducer.insuredID;
  request.Coverages= state.SelectedCoverageSelectionOption_datalist;
  const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);

  if(response.data && response.data.StandaloneCoverage){
    triggerInfoToast(response.data.StandaloneCoverage ,  { position: Toast.POSITION.TOP_RIGHT})
    throw new Error
  }
  return;
}
catch(error){
  console.log("saveCoverageSelectionMPL error",error);
  throw new Error
}
}

const saveCoverageDetailsMPL=async(state,props)=>{

  if(!props.UIFlowReducer.IsCoverageSave){
    triggerInfoToast("Employee benefit plan is required.",  { position: Toast.POSITION.TOP_RIGHT})
    throw new Error
  }

}

const componentDidUpdate_HomeOwners=async(state, props, UpdateState)=>{
  try{
    if (props.UIFlowReducer.insuredID  || props.UIFlowReducer.agentID){
      let agentResponse = await  getAgencyAgent(state,props,UpdateState);
      props.setAgencyDetails(agentResponse.Agency);
      props.setAgentDetails(agentResponse.Agent);
    }
  }
  catch(error){
    console.log("Error in componentDidUpdate_HomeOwners", error)
  }
}

const componentDidMount_HomeOwner=async(state, props, UpdateState)=>{
  try{
    if (props.UIFlowReducer.insuredID ){
      let agentResponse = await  getAgencyAgent(state,props,UpdateState);
      props.setAgencyDetails(agentResponse.Agency);
      props.setAgentDetails(agentResponse.Agent);
    }
  }
  catch(error){
    console.log("Error in componentDidMount_HomeOwners", error)
  }
}
const componentDidUpdate_CL=async(state, props, UpdateState)=>{
  try{
    if (props.UIFlowReducer.insuredID ){
      let agentResponse = await  getAgencyAgent(state,props,UpdateState);
      props.setAgencyDetails(agentResponse.Agency);
      props.setAgentDetails(agentResponse.Agent);
    }
  }
  catch(error){
    console.log("Error in componentDidUpdate_CL", error)
  }
}

export const getSubmission= async(state, props, UpdateState)=>{
  try{
    if(props.UIFlowReducer.submissionData.ID){
      let request={};
      const currentPageIndex  = props.UIFlowReducer.currentPageIndex;
      request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
      request.OwnerId = sessionStorage.getItem("OwnerID");
      request.TargetEventName=props.pageNavigation[currentPageIndex-1].GeteventName;
      request.ID=props.UIFlowReducer.submissionData.ID;
      const reduxState = store.getState();
      request.EventName=GetCommonEventName();
      const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
      UpdateState("pagelevelValues",{...state.pagelevelValues,...response.data},true);
      props.setexpandPanelData(true)
      return response
    }
  }
  catch(error){
    console.log("Error in getSubmission API", error)
  }
}




export  const SubmissionUtils ={
    CreateSubmission:CreateSubmissionorUpdate,
    saveUnderwritingQuestions:saveUnderwritingQuestions,
    UpdateNavigation:UpdateNavigation,
    saveAccountInsured:saveAccountInsured,
    saveAccountInsured_RateIndication:saveAccountInsured_RateIndication,
    saveAccountInsuredMPL:saveAccountInsuredMPL,
    saveAccountInsuredCL:saveAccountInsuredCL,
    saveCoverageSelectionMPL:saveCoverageSelectionMPL,
    saveCoverageDetailsMPL,
    "commonSubmissionUtil": {
      componentDidMount: componentDidMount_HomeOwners,
      componentDidUpdate: componentDidMount_HomeOwners
    },
    "MPL": {
      getSubmission,
      "Insured_MPL": {
      componentDidMount: componentDidMount_MPL,
      componentDidUpdate: componentDidMount_MPL
    },
    "AgencyAgent_MPL": {
      componentDidMount: componentDidMount_AgencyAgent_MPL,
      componentDidUpdate: componentDidMount_AgencyAgent_MPL
    }
  },
  "PL": {
    "Orchid_Insured": {
      componentDidUpdate:existingInsured,
      componentDidMount: componentDidMount_HomeOwners
    },
    "Orchid_Agency": {
      componentDidUpdate:componentDidUpdate_HomeOwners,
      componentDidMount:componentDidMount_HomeOwner
    },
    "Verdant_Insured": {
      componentDidUpdate:componentDidUpdate_HomeOwners_Insured,  
      componentDidMount: componentDidMount_HomeOwners_Insured
    },
  },
  "AU": {
    "Orchid_Insured": {
      componentDidUpdate:existingInsured,
      componentDidMount: componentDidMount_HomeOwners
    },
    "Orchid_Agency": {
      componentDidUpdate:componentDidUpdate_HomeOwners,
      componentDidMount:componentDidMount_HomeOwner
    }
  },
  "CL": {
    "CL_Insured": {
      componentDidUpdate:existingInsured_CL,
      componentDidMount: componentDidMount_CL
    },
    "CL_Agency": {
      componentDidUpdate:componentDidUpdate_CL
    }
  }
}
