import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from "@material-ui/icons/Search";
import { Typography } from '@material-ui/core';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from 'axios';
import { commonAutoCompleteMethod } from './ButtonUtil';

const SmartInput = (props) => {

    // props
    const { component, componentvalue } = props;
    const {LookupDisplayName, LookupValue} = component.Action;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([] || {});
    const [loading, setLoading] = useState(false);
    const searchQuery=useRef('');
    const showSearchIcon = !componentvalue ? true : false;
    let valueforText = "";
    if (component.DefaultValue === "" && componentvalue !== undefined) {
        valueforText = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue !== undefined) {
        valueforText = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue === undefined) {
        valueforText = component.DefaultValue;
    }
    if (component.DefaultValue === "" && componentvalue === undefined) {
        valueforText = "";
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue === "" &&
      componentvalue !== undefined
    ) {
      valueforText = componentvalue;
    }
    // useEffect for fetch data
    useEffect(() => {
        let timerID = null;
        let axiosCancelToken = axios.CancelToken.source();
        let timer=0.8;
        if(component.Action && component.Action.timeDelay && component.Action.timeDelay!== '' && !isNaN(Number(component.Action.timeDelay)))
        timer=Number(component.Action.timeDelay);
        const fetchData = async () => {
            if (!open) {
                setLoading(false);
            }
            if(open) {
                timerID = setTimeout(() => {
                    invokeAutocomplete(axiosCancelToken)
                }, timer*1000)
            }
        }
        fetchData();

        return () => {
            if (axiosCancelToken) axiosCancelToken.cancel();
            if(timerID) clearTimeout(timerID);
         }
    }, [open,searchQuery.current])
    useEffect(() => {
        if(props.component.DefaultValue !== "" &&  props.componentvalue === undefined  &&  props.updateValueWithSubplicationName)
           props.updateValueWithSubplicationName({ [ props.component.AttributeName]:  props.component.DefaultValue },  props.component);
      }, [props.updateValueWithSubplicationName]);

    const invokeAutocomplete = async (axiosCancelToken) => {
        try {
            setLoading(true);
            console.log('called')
            if(Object.keys(component.Action).length > 0){
                let {Options:response=[]} = await getList(props,searchQuery.current,axiosCancelToken);
                setLoading(false);
                setOpen(false);
                if (Array.isArray(response) && response.length > 0) {
                    setOptions(response);
                }
                else if (typeof response === 'object' && Object.keys(response).length > 0) {
                    setOptions(response[component.ResponseKey]);
                }
                else {
                }
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            if (err === 'Cancelled') {
                return;
            }
            console.log(err);
        }
    }
    // handleOptionSelect
    const handleOnChange = async (data) => {
       let newObj = {};
        if (data) {
            let displayValue = data.LookupDisplayValue ? data.LookupDisplayValue : data[LookupDisplayName] ?  data[LookupDisplayName]: data.DisplayName      
            newObj = {
                ...data,
                [component.AttributeName]: data[LookupValue] ? data[LookupValue] : displayValue
            }
        }
        else
        {
        newObj = {
            [component.AttributeName]: '',
            ...data
        }}
        if(component.Action && (component.Action.onChange && Object.keys(component.Action.onChange).length > 0) && data){
            await commonAutoCompleteMethod({...props.component, Action: {...component.Action.onChange,handlerNeeded:true}},newObj, props);
        }
        else
        props.handleAutoCompletechange(newObj, component.ItemTipParams, component);
        setOptions([])
    }
    const handleTextChange=(e)=>{
        setOpen(true);
        setOptions([]);
        searchQuery.current=e.target.value;
        const value={[component.AttributeName]:e.target.value};
        let timer=0.8;
        let componentCopy={...component}
        if(component.Action && component.Action.timeDelay && component.Action.timeDelay!== '' && !isNaN(Number(component.Action.timeDelay)))
        timer=Number(component.Action.timeDelay);
        if(Object.keys(component.Action).length > 0 && !component.Action.timeDelay){
            componentCopy.Action.timeDelay=timer;
        }else{
            componentCopy.Action={};
            componentCopy.Action.timeDelay=timer;
        }
        props.handleInputChangeV2(value, componentCopy, props.isDynamicTable, props.datatableRowindex, props);
    }
    const getList = async(props,searchQuery,axiosCancelToken) => {
        let response = {};
        if(component.Action && (component.Action.onLoad && Object.keys(component.Action.onLoad).length > 0)){
            response = await commonAutoCompleteMethod({...component, Action: component.Action.onLoad},{}, {...props,axiosCancelToken},searchQuery);
        }
        return response ? response : {};
    }

    ValidatorForm.addValidationRule('IsEmpty', (value) => {
        if (!value) {
            return false;
        }
        return true;
    });
    
    let isRequired = component.IsRequired === "Y" ? true : false;
    const { variant } = props
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
        validations.push('required');
        errorMessages.push(component.RequiredMessage);
        validations.push('IsEmpty');
        errorMessages.push(component.RequiredMessage);
    }
    
    if (component.ValidationRegex) {
        validations.push('matchRegexp:'+ component.ValidationRegex);
        errorMessages.push(component.ValidatorMessage);
    }
    
    return (
        <Grid style={styles.mainGrid} className='smartinput' item xs={12} md={component.Size? component.Size :7} lg={component.Size? component.Size :7} sm={9} key={component.AttributeName}>
            <TextValidator
            name={component.AttributeName}
            placeholder={component.ComponentLabel.replace(/&nbsp;/g, '')}
            label={
                !props.isDynamicTable && (
                <Typography className="custom-label-down text-font small_label" style={styles.label}>
                    {component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}
                </Typography>
                )
            }
            value={valueforText}
            defaultValue={valueforText}
            className={`custom-input ${component.StyleClass} lineHeight`}
            onChange={(e) => handleTextChange(e)}
            onBlur={()=>setOptions([])}
            onFocus={()=>setOpen(true)}
            disabled={component.IsReadonly}
            validators={validations}
            variant={variant}
            errorMessages={errorMessages}
            fullWidth
            InputProps={{
                endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {showSearchIcon && !valueforText && <IconButton> <SearchIcon /> </IconButton>}
                    </React.Fragment>
                )
            }}
            autoComplete={"off"}
            />
            {options.length > 0 && 
                <div id="smartinput">
                    {options.map(el=>{
                    return <div className="small_options" onMouseDown={()=>handleOnChange(el)}>{el[LookupDisplayName]}</div>
                    })}
            </div>
            }
        </Grid>
    )

}

const styles = {
    mainGrid : {
        position : 'relative'
    }
}

export default SmartInput;
