import React, { Component } from "react";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormatCustom from './NumberFormatCustom';
import { renderIcon } from '../utils/LOB_Util/AU/VIN_Validator';

class Currency extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currencyValidation: false,
    }
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('minDecimalNumber', (value, mindecimalvalue) => {
      //console.log(value,this.state.mindecimalvalue,a)
      if (parseFloat(value) < parseFloat(mindecimalvalue)) {
          //alert("hello")
          return false;
      }
      return true;
  });
  ValidatorForm.addValidationRule('maxDecimalNumber', (value, maxdecimalvalue) => {
      //console.log(value,this.state.maxdecimalvalue)
      if (parseFloat(value) > parseFloat(maxdecimalvalue)) {
          return false;
      }
      return true;
  });
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined ){
  //     this.props.updateValueWithSubplicationName && this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component, this.props, prevProps);
  //   }
  // }

  onChange = (e) => {
    e.target.value = e.target.value && e.target.value.trim() === "" ? "" : e.target.value;
    if (this.props.customTable) {
      let value = Object.assign({}, { [e.target.name]: e.target.value.replace(/,/g, "") });
      this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex,this.props);
    }
    else if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]) {
        let value = Object.assign({}, { [e.target.name]: e.target.value });
        this.props[this.props.component.OnChangeAction](value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
    else {
      let value = Object.assign({}, { [e.target.name]: e.target.value.replace(/,/g, "") });
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
    }
  }

  onKeyDown = (e) =>{
    if(this.props.component.OnKeyDownAction!=='' ){
      this.props[this.props.component.OnKeyDownAction] && this.props[this.props.component.OnKeyDownAction](this.props,e);
    }
    else {
      this.props.onKeyDown && this.props.onKeyDown(this.props,e);
     }
  }

  onBlur =  (e) => {
    let {component}=this.props;
    component.OnBlurAction && this.props[component.OnBlurAction] &&  this.props[component.OnBlurAction](this.props,e);
  }

  render() {

    const { component, pagelevelValues, variant} = this.props;
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
    const validations = [];
    const errorMessages = [];
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }
    if (component.ValidationRegex &&   !isReadOnly) {
      validations.push('matchRegexp:'+ component.ValidationRegex);
      errorMessages.push(component.ValidatorMessage);
      }
    if (component.MinValue !== "No Limit" && component.MinValue > 0) {
      validations.push('minNumber:' + component.MinValue);
      errorMessages.push(component.ValidatorMessage);
      validations.push('minDecimalNumber:' + (component.MinValue === '-1' ? 0 : component.MinValue));
      errorMessages.push(component.ValidatorMessage)
    }
    if (component.MaxValue !== "No Limit" && component.MaxValue > 0) {
      validations.push('maxNumber:' + component.MaxValue);
      errorMessages.push(component.ValidatorMessage);
      validations.push('maxDecimalNumber:' + component.MaxValue);
      errorMessages.push(component.ValidatorMessage)
    }
    if (this.state.currencyValidation) {
      validations.push("isCurrency");
      errorMessages.push("Invalid Format");
    }

    let value = "";

    if (component.DefaultValue === "" && component.AttributeValue === "" && componentvalue !== "" && componentvalue !== undefined) {
      value = componentvalue
    }
    if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue !== undefined) {
      value = componentvalue
    }
    if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue === undefined) {
      value = component.DefaultValue
    }
    if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue === undefined) {
      value = "";
    }
    if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue !== undefined) {
      value = componentvalue;
    }


    let styleObject = {};
    if(component.AdjacentLabel)
      styleObject = {display:'flex'};
    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} style={styleObject}>
        {
        component.AdjacentLabel ==="Y" ?
          <>
            <Grid item xs={4} sm={4} md={4} lg={4}>
                <InputLabel htmlFor={component.AttributeName} className={`custom-label-down text-font adjacent_label ${component.AttributeStyleClass}`}>
                  {componentLabel}
                </InputLabel>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} className="adjacent_TextValidator_Grid">
              <TextValidator
              name={component.AttributeName}
              id={component.AttributeName}
              placeholder={!this.props.isDynamicTable && component.ComponentLabel}
              value={value}
              defaultValue={value}
              autoComplete={autocomplete}
              className={`custom-input custom-dollar ${component.StyleClass} lineHeight`}
              onChange={(e) => this.onChange(e)}
              margin="normal"
              disabled={isReadOnly}
              validators={validations}
              onBlur={(e) => this.onBlur(e)}
              errorMessages={errorMessages}
              variant={variant}
              fullWidth
              onKeyDown={(e) => this.onKeyDown(e)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                endAdornment: renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
              }}
              />
            </Grid>
          </>
          :
          <TextValidator
            name={component.AttributeName}
            placeholder={!this.props.isDynamicTable && component.ComponentLabel}
            label={
              !this.props.isDynamicTable && (
                <Typography className={`custom-label-down small_label text-font ${component.AttributeStyleClass}`}>
                  {componentLabel}
                </Typography>
              )
            }
            value={value}
            defaultValue={value}
            autoComplete={autocomplete}
            className={`custom-input custom-dollar ${component.StyleClass} lineHeight`}
            onChange={(e) => this.onChange(e)}
            margin="normal"
            disabled={isReadOnly}
            validators={validations}
            onBlur={(e) => this.onBlur(e)}
            errorMessages={errorMessages}
            variant={variant}
            fullWidth
            onKeyDown={(e) => this.onKeyDown(e)}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: <InputAdornment position="start">$ </InputAdornment>,
              endAdornment: renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
            }}
          />
        }
      </Grid>
    );
  }
}

export default Currency;
