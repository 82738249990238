import {  dynamicObjectConstruction, showtoaster } from "./NoCodeUtils";
import { format } from "date-fns";
import { AxiosInstance, LocationandBuildingAxios, getServiceRequestDetail } from "./API";
import { downloadFile } from "../../../../CommonAxios/FileHandlerAxios";
import { v4 as uuidv4 } from 'uuid';
import store from "../../../../store";
import appActions from "../../../../actions";
import { triggerToast } from "./HelperUtils";
import { constructAndInvokeMetadataCall,deleteDependentAttribute,resetValidation } from "./MetaUtils";
import { subAppListConstruction } from "./DetailSummarySubAppList";
import { EntitySubAppTranslationV2, EntitySubAppTranslationV3, getEntitySubAppMappingV2 } from "../requestConstruction/ResponseTranslation";
import { removeDiacritics } from "./CommonUtils";
import container from "../DIContainer";
import { constructRequestValues, constructRequestValuesV2 } from "../API_utils/ConstructRequestValues";
import Axios from "axios";

const { actionType } = appActions;
const debounceCall = function () {
  let timer;
  return function (callBack, timeDelay) {
      clearTimeout(timer);
      timer = setTimeout(callBack, timeDelay*1000);
  }
}()

export const handleAutoCompletechange = async (value, item, component,state,subAppInfo,functions) => {
  let autoComplete_values = {};
  autoComplete_values=value;
  let {updateStateValues=false} = functions;
    // this.setState((prevState) =>
    {
      let values = Object.assign({}, state.values);
      let pagelevelValues = Object.assign({}, state.pagelevelValues);
      let transactionPrimaryObject = JSON.parse(JSON.stringify(state.transactionPrimaryObject));
      if(!pagelevelValues[component.SubApplicationName])
      {
        pagelevelValues[component.SubApplicationName]={}
      }
      pagelevelValues={...pagelevelValues,[component.SubApplicationName] : {...pagelevelValues[component.SubApplicationName],...value}}
      if(!transactionPrimaryObject[component.SubApplicationName])
      {
        transactionPrimaryObject[component.SubApplicationName]={}
      }
      transactionPrimaryObject={...transactionPrimaryObject,[component.SubApplicationName] : {...transactionPrimaryObject[component.SubApplicationName],...value}}
      values = Object.assign({}, values, autoComplete_values);
      let response ={};
      if (component.AjaxNeeded==='true') {
      let effectiveDate = (state.values && state.values.EffectiveDate) ? state.values.EffectiveDate :  format(new Date(), 'yyyy-MM-dd')
      response= await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate });
      updateStateValues && updateStateValues({ pagelevelValues, transactionPrimaryObject, values,pageMetadata: response  })
      }
      updateStateValues && updateStateValues({ pagelevelValues, transactionPrimaryObject, values })

    }
    // );
};

export const handleHiddenInputChange = (component, state) => {
  let attributeName = component.AttributeName;
  let attributeValue = component.DefaultValue;
  if (component.SubApplicationAttributeValueList.length == 2) {
    attributeValue = component.SubApplicationAttributeValueList[1].Value;
  }
  let values = Object.assign({}, state.values);
  values[attributeName] = attributeValue;

  let { SubApplicationName: subappName } = component;
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);
  if (typeof subappName !== "string") {
    return;
  }
  if (!pagelevelValues[subappName]) {
    pagelevelValues[subappName] = {};
  }
  pagelevelValues[subappName][attributeName] = attributeValue;

  if (!transactionPrimaryObject[subappName]) {
    transactionPrimaryObject[subappName] = {};
  }
  transactionPrimaryObject[subappName][attributeName] = attributeValue;

  return { values, pagelevelValues, transactionPrimaryObject }
};

export const mergeAttributeListwithdata = (dataList, attributeList, dataObj) => {
  try {
    dataList && dataList.forEach((data) => {
      let attList=JSON.parse(JSON.stringify(attributeList))
          attributeList.length && attList.forEach(attribute => {

            if (data['IsEditable'] && data['IsEditable'] !== "Y") {
              attribute['IsReadonly'] = true
            } else{
              //  Need to revamp on this

              let isSkip = attributeList.every(att=> att['IsReadonly'] === true)
                if(!isSkip) {
                  if((data['IsTaxOverridden']==='Yes'|| data['IsFeeOverridden'] ==='Yes')){
                    attribute['IsReadonly'] = false
                  }
                }
            }
            setDefaultValuewithData(attribute,data)
        })
      data["rowMetaData"]= attList
    })
    return dataList;
  }
  catch (err) {
    console.log("error in mergeAttributeListwithdata", err);
  }
}

const setDefaultValuewithData=(val,data)=>{
    if(data.hasOwnProperty(`${val.AttributeName}`)){
      val.DefaultValue=data[val.AttributeName]
   }
}

// For Verdant: Cancel metadataAPI and call the meta call.
async function Metadata(value,component,props,state, functions, DynamicProps, cancelAPICall){
  try{
  if(cancelAPICall.current && cancelAPICall.current.Metadata){
      cancelAPICall.current.Metadata.cancel("Metadata canceled")
  }
  cancelAPICall.current = {...cancelAPICall.current, Metadata: Axios.CancelToken.source()}

  let { objectId, objectVerId } = DynamicProps.pageInfo
  let subAppInfo={
      objectId,
      objectVerId,
  }

  let effectiveDate = (state.values && state.values.EffectiveDate) ? state.values.EffectiveDate : (props.values && props.values.EffectiveDate) ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')

  let isLoaderNeeded=true
      if("isLoaderNeeded" in DynamicProps.pageInfo && DynamicProps.pageInfo.isLoaderNeeded===false){
          isLoaderNeeded=false
      }
      if("isLoaderNeeded" in component.Action){
          isLoaderNeeded=component.Action.isLoaderNeeded
      }


  const cancelToken =(cancelAPICall.current && cancelAPICall.current['Metadata'] && cancelAPICall.current['Metadata'].token) || ''
  let dataListKey = DynamicProps.componentState && DynamicProps.componentState.ResponseKey;
  let IteratorIndex = DynamicProps.componentState && DynamicProps.componentState.IteratorIndex;

  if (dataListKey && typeof IteratorIndex === "number" && state[dataListKey]) {
    const dataList = state[dataListKey]
    dataList[IteratorIndex]["Metadata_loading"] = true
    functions.updateValues({loading: isLoaderNeeded, isLoaderNeeded, [dataListKey]: dataList})
    subAppInfo.subappName = DynamicProps.pageInfo.iteratorSubappList;
    let transactionPrimaryObject = {...state.transactionPrimaryObject, ...dataList[IteratorIndex]}
    //Temporary for verdant coverage Selection page
    let selectedSubline = DynamicProps.pageInfo.selectedSubline ? DynamicProps.pageInfo.selectedSubline : {};
    if(Object.keys(selectedSubline).length > 0){
      let choosedsubline = DynamicProps.componentState && DynamicProps.componentState.SublineName ? DynamicProps.componentState.SublineName : '';
      let neededSubapps = []
      Object.entries(selectedSubline).forEach(([key,value])=>{
        if(key === choosedsubline){
             neededSubapps.push(value)
        }
      })
      subAppInfo.subappName = [...subAppInfo.subappName, ...neededSubapps]
    }


    let metadataResponse = await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject, EffectiveDate: effectiveDate}, null,{cancelToken} );
    dataList[IteratorIndex]["Metadata_loading"] = false
    if(metadataResponse === 'APIcancelled'){
      functions.updateValues({loading:false, [dataListKey]: dataList})
    }
    else{
    let SubApplicationDetailList = metadataResponse.ApplicationDetail.SubApplicationDetailList;
    dataList[IteratorIndex]["SubApplicationDetailList"] = SubApplicationDetailList
    functions.updateValues({ [dataListKey]: dataList, loading:false})
    }
    return;
  }

  subAppInfo.subappName =DynamicProps.pageInfo &&DynamicProps.pageInfo.subappNameList?DynamicProps.pageInfo.subappNameList: DynamicProps.componentState.subappNameList;

  let response=await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: state.transactionPrimaryObject, EffectiveDate: effectiveDate }, null,{cancelToken}  )
  if(response === 'APIcancelled'){
      functions.updateValues({loading:false,'Metadata_loading': false})
  }
  else{
    functions.updateValues({pageMetadata: response, loading:false, 'Metadata_loading': false})
  }
  }
  catch(e){
      console.log("Error in Metadata", e)
  }
}


// For Verdant: Running commonChangeMethod this is config for verdant alone.
async function callbackAPI(value,component,props,state, functions, DynamicProps, cancelAPICall){
  try{

    if(component.Action.Actions && component.Action.Actions.length > 0){
          if(component.Action.Actions && component.Action.Actions.length > 0){
              await commonChangeMethod(component.Action.Actions ,value ,DynamicProps, state, component,  state.transactionPrimaryObject, state.pagelevelValues, this.cancelAPICall)
             }
      }
     else if (component.AjaxNeeded==='true') {
      await Metadata(value,component,props,state, functions, DynamicProps, cancelAPICall)
    }
  }
  catch(e){
      console.log('Error in Callback', e)
  }
}

// For Verdant: If action has timeDelay use debounce then callback or callback
export const handleInputCallbackV1 = async(value,component,props,state, functions, DynamicProps, cancelAPICall) => {
  try{
    if(Object.keys(component.Action).length>0 && (component.Action.timeDelay!==undefined && component.Action.timeDelay!== '' && typeof(Number(component.Action.timeDelay))==='number')){
       debounceCall(() => callbackAPI(value,component,props,state, functions,DynamicProps, cancelAPICall), component.Action.timeDelay)
    }else{
       callbackAPI(value,component,props,state, functions, DynamicProps, cancelAPICall)
    }
  }
  catch(e){
    console.log("Error in handleInputCallbackV1", e)
  }
}

// For Verdant: Do the handle input change. Update the new value along with other previous val
export const handleInputChangeV4 = (value,component,props,state, functions, DynamicProps) => {
  try{
    let obj = {};
    let dataListKey = DynamicProps.componentState && DynamicProps.componentState.ResponseKey;
    let IteratorIndex = DynamicProps.componentState && DynamicProps.componentState.IteratorIndex;

      if (dataListKey && typeof IteratorIndex === "number" && state[dataListKey]) {
        let dataList = state[dataListKey].map((data, index) => {
          if (index === IteratorIndex) {
            let subappObj = data[component.SubApplicationName] || {};
            let dataObj = {
              [component.SubApplicationName]: { ...subappObj, ...value },
            };
            deleteDependentAttribute(component,state.transactionPrimaryObject, data);
            return { ...data, ...dataObj };
          }
          return data;
        });
        obj = { [dataListKey]: dataList };
        return obj;
      }

          let keys = Object.keys(value);
          let attributeName = keys[0];
          let attributeValue = value[attributeName]
          if (value[attributeName] && value[attributeName].constructor !== Array && value[attributeName].constructor !== File) {
            attributeValue = value[attributeName].toString();
          }
          if(value[attributeName] && value[attributeName].constructor === File){
            attributeValue = value[attributeName]
          }
          attributeValue =  removeDiacritics(attributeValue, component, DynamicProps)
          let subappName = component.SubApplicationName;
          let pagelevelValues = state.pagelevelValues;
          let transactionPrimaryObject = state.transactionPrimaryObject;
          let values = state.values;
          if (!pagelevelValues[subappName]) {
              pagelevelValues[subappName] = {};
            }
            if (!transactionPrimaryObject[subappName]) {
              transactionPrimaryObject[subappName] = {};
            }
            if(keys.length>1){
              pagelevelValues = {...pagelevelValues, [subappName]: {...pagelevelValues[subappName], ...value}}
              transactionPrimaryObject = {...transactionPrimaryObject, [subappName]: {...transactionPrimaryObject[subappName], ...value}}
            }
            else{
              pagelevelValues[subappName][attributeName] = attributeValue;
              transactionPrimaryObject[subappName][attributeName] = attributeValue;
              values[attributeName] = attributeValue;
            }
            obj = { pagelevelValues, transactionPrimaryObject, values}
            deleteDependentAttribute(component, pagelevelValues,transactionPrimaryObject,DynamicProps)
            return obj;
  }
  catch(e){
    console.log("Error in InputUtils handleInputChangeV4", e)
  }
}

export const handleInputChangeV2 = async (value, component, props, state, functions, subAppInfo, callback, needMetadataCall=false, DynamicProps,cancelAPIRef) => {
  try {
    let {updateStateValues=false} = functions;
    let keys = Object.keys(value);
    let attributeName = keys[0];
    let attributeValue = value[attributeName]
    if (value[attributeName] && value[attributeName].constructor !== Array && value[attributeName].constructor !== File) {
      attributeValue = value[attributeName].toString();
    }
    if(value[attributeName] && value[attributeName].constructor === File){
      attributeValue = value[attributeName]
    }
    if (component.OnChangeAction && functions[component.OnChangeAction]) {
      functions[component.OnChangeAction](attributeValue, component)
    }
    attributeValue =  removeDiacritics(attributeValue, component, DynamicProps)
    let subappName = component.SubApplicationName;
    let pagelevelValues = Object.assign({}, state.pagelevelValues);
    let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);
    let values = Object.assign({}, state.values);
    if (!pagelevelValues[subappName]) {
      pagelevelValues[subappName] = {};
    }
    if (!transactionPrimaryObject[subappName]) {
      transactionPrimaryObject[subappName] = {};
    }
    if(keys.length>1){
      pagelevelValues = {...pagelevelValues, [subappName]: {...pagelevelValues[subappName], ...value}}
      transactionPrimaryObject = {...transactionPrimaryObject, [subappName]: {...transactionPrimaryObject[subappName], ...value}}
      deleteDependentAttribute(component,pagelevelValues,transactionPrimaryObject)
    }
    else{
      pagelevelValues[subappName][attributeName] = attributeValue;
      transactionPrimaryObject[subappName][attributeName] = attributeValue;
      values[attributeName] = attributeValue;
    }

    let dataListKey = DynamicProps && DynamicProps.componentState && DynamicProps.componentState.ResponseKey;
    let IteratorIndex =DynamicProps && DynamicProps.componentState && DynamicProps.componentState.IteratorIndex;
    let IteratorList = {}
    if(dataListKey && typeof IteratorIndex === 'number'){
      let dataList = DynamicProps.componentState[dataListKey] || DynamicProps.componentProps[dataListKey]
     dataList = dataList.map((data, index)=>{
      if(index === IteratorIndex){
        let subappObj = data[component.SubApplicationName] || {}
        let dataObj = {[component.SubApplicationName]: {...subappObj,...value}}
       return {...data, ...dataObj}
      }
      return data
    })
    IteratorList = {[dataListKey]: dataList}
    }

    deleteDependentAttribute(component,state.pagelevelValues,state.transactionPrimaryObject,props)
    if (needMetadataCall !== false) {
      // let componentType = ["select", "radio", "checkbox", "booleancheckbox", "multiplecheckbox", "calendar"]
      if (component.AjaxNeeded==='true') {
      // if (componentType.includes(component.ComponentType.toLowerCase())) {
        let obj={};
        if (typeof callback === 'function') {
          updateStateValues && updateStateValues({ pagelevelValues, transactionPrimaryObject, ...IteratorList })
          callback()
          resetValidation(component,props)
          return { pagelevelValues, transactionPrimaryObject, values}
        }

        let {UpdateState,updateValues} = DynamicProps && DynamicProps.functions ?  DynamicProps.functions : functions
        let{Action}= component
        let{isLoaderNeeded = true} = Action
        isLoaderNeeded && UpdateState && UpdateState("loading", true, true)
        // props.showLoader();
        const Controls = container.get('componentFunction')
        let effectiveDate = (state.values && state.values.EffectiveDate) ? state.values.EffectiveDate : (props.values && props.values.EffectiveDate) ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
        if(Object.keys(component.Action).length>0 && (component.Action.timeDelay!==undefined && component.Action.timeDelay!== '' && typeof(Number(component.Action.timeDelay))==='number')){
         debounceCall(async()=>{
          try {
            isLoaderNeeded && UpdateState && UpdateState("loading", true, true)
            if(component.Action.Actions && component.Action.Actions.length > 0){
             await commonChangeMethod(component.Action.Actions ,value ,DynamicProps, state, component,  transactionPrimaryObject, pagelevelValues,cancelAPIRef)
            }
            else if(Object.keys(component.Action).length>0 && Controls[component.ComponentType.toLowerCase()] !==undefined  ){
              if(typeof Controls[component.ComponentType.toLowerCase()] !=='function'){
                  isLoaderNeeded && UpdateState && UpdateState("loading", false, true)
                throw new Error('Something Went Wrong')
              }else{
                await Controls[component.ComponentType.toLowerCase()](component,value,DynamicProps,false)
              }
            }
            else{
            let tkeys = {}
            if(component.Action.AdditionalTPOInfo && component.Action.AdditionalTPOInfo.CustomUpdate) {
              tkeys = await dynamicObjectConstruction(component.Action.AdditionalTPOInfo.CustomUpdate, {...value,...props}, {}, "custom");
          }
            let  debounceTPO = {...transactionPrimaryObject, ...tkeys}
            let response=await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: debounceTPO, EffectiveDate: effectiveDate })
              // obj.pageMetadata=response
              UpdateState && UpdateState("pageMetadata", response, false)
              updateStateValues && updateStateValues({pagelevelValues, transactionPrimaryObject, pageMetadata:response})
              let columnList={}
              Object.keys(state).map(key=>{
                  if(key.endsWith("_columnList")){
                      columnList[key]=[]
                  }
              })
              updateValues && Object.keys(columnList).length > 0 && updateValues(columnList)
              isLoaderNeeded && UpdateState && UpdateState("loading", false, true)
            }
          }
          catch (error) {
            console.log(error,'error in debounceCall')
          }},Number(component.Action.timeDelay))
        }else{
        UpdateState && UpdateState("Metadata_loading", true, true)
        let response = await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate });
        obj.pageMetadata=response
        obj.Metadata_loading = false
        }
        // props.hideLoader();
        UpdateState && UpdateState("loading", false, true)
        updateStateValues && updateStateValues({ pagelevelValues, transactionPrimaryObject, values, ...IteratorList,...obj  })
        resetValidation(component,props)
        return { pagelevelValues, transactionPrimaryObject, values, ...IteratorList, ...obj }
      // }
    }
    }
    updateStateValues && updateStateValues({ pagelevelValues, transactionPrimaryObject, values, ...IteratorList })
    resetValidation(component,props)
    return { pagelevelValues, transactionPrimaryObject, values, ...IteratorList};
  }
  catch(exception) {
    console.log("Error in InputUtils handleInputChangeV2", exception)
    triggerToast("Error in handleInputChangeV2");
  }
}

export const handleAttachments=async(attachedFile,setAttachment,attachment,props,uploadDocuments)=>{
  try{
    let FileLocationKey ;
    let FileNameKey ;
   if(props.component && props.component.AttributeParams){
    let attributeParams=props.component.AttributeParams.split(",")
    let attributeInfo  = {};

    attributeParams.forEach((attributeParam,index) => {
      if(index >= 0){
          let item = attributeParam.split("~");
          attributeInfo[item[0]] = item[1];
      }
    })
    FileLocationKey = attributeInfo && attributeInfo.FileLocationKey ? attributeInfo.FileLocationKey : "AttachmentUrl"
    FileNameKey = attributeInfo && attributeInfo.FileNameKey ? attributeInfo.FileNameKey : "AttachmentName"
  }
  else{
    FileLocationKey =  "AttachmentUrl"
    FileNameKey =  "AttachmentName"
  }

    if(uploadDocuments){
      let filename;
      let valueForAttachment=JSON.parse(JSON.stringify(attachment));
    for (let idx in attachedFile){
        if(attachedFile[idx].name && typeof(attachedFile[idx])=='object'){
          const fileToUpload = new FormData();
          fileToUpload.append("uploadFileData",attachedFile[idx],attachedFile[idx].name);
          let {data:fileUploadResponse} = await AxiosInstance.post("/core/fileUpload?pathVariable=ATTACHMENTS_POLICY", fileToUpload);
          filename = attachedFile[idx].name && attachedFile[idx].name.substring(0,attachedFile[idx].name.lastIndexOf('.'));
            // valueForAttachment.push({[props.component.ResponseKey]:filename,AttachmentUrl:fileUploadResponse.relativePath,AttachmentName: fileUploadResponse.newFileName});

            valueForAttachment.push({[FileLocationKey]:fileUploadResponse.relativePath,[FileNameKey]: fileUploadResponse.newFileName});
              setAttachment([...valueForAttachment])
            }
          }
    }
    else{
      let {[FileLocationKey]:AttachmentLocation,[FileNameKey]:AttachmentName}=attachedFile;
      let redux =store.getState()
      let transactionRootPath=redux.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH;
      let path=`${transactionRootPath}${AttachmentLocation}`;
      downloadFile(AttachmentName,path);
    }
  }catch(e){
    console.log("Error in uploadEmail_HomeOwners",e);
  }
}

export const updateArrayValueOnState = async (key, value, expand, props, rowMetaDataNeeded, subappName) => {
  let arr = [];
  value['isExpanded'] = expand.toString();
  let { ID, uid } = value;
  let checkKey = ID ? 'ID' : uid ? 'uid' : null;

  if (props.componentState && props.componentState[key] !== undefined && Array.isArray(props.componentState[key])) {
      arr = props.componentState[key];
  }
    if ((checkKey === 'ID' && ID !== undefined) || (checkKey === 'uid' && uid !== undefined)) {
      let updated = false;
      let newObj ={}
      await arr.map((el, idx) => {
          if (el[checkKey] === value[checkKey]) {
            if (Array.isArray(subappName) && subappName.length > 0) {
            subappName.map((e) => {
              newObj = { ...value, ...value[e] }
              delete value[e]; delete newObj[e];
            })
          } else {
            newObj = { ...value, ...value[subappName] }
            delete newObj[subappName]
          }
          newObj['isExpanded'] = expand.toString();
          arr[idx] = newObj;
          updated = true;
      }
      })
      if (updated === false) {
          if(typeof(props.page)==='number'){
            if(props.page >= 0 && Math.ceil((props.dataList.length+1)/props.rowsPerPage)===(props.page+2))
            props.tableFunctions && props.tableFunctions.updateDataTableState && props.tableFunctions.updateDataTableState('navigatePage',true,false);
          }
          arr.push(value);
      }
  } else {
      arr.push(value);
  }
  if(rowMetaDataNeeded){
    arr =  mergeAttributeListwithdata(arr,props.columnList);
  }
  props.UpdateState(key, arr, true)
}
export const onClickSave = (key, subname, value, expand, ID,pageMetadata,pagelevelValues,props,state) => {
  // this.state.currentIDs.push(ID);
  // updateArrayValueOnStateV2(subname, value, key, expand,props,state);

  updateArrayValueOnStateV2(key, value, expand, props, subname, pagelevelValues, state);
}

export const onChildSave = (key, subAppName, value, expand, ID, pageMetadata, pagelevelValues, props, state) => {
  updateArrayValueOnState(key, value, expand, props, false, subAppName);
}
export const showDialog = async(key, props, templateName) =>{
  store.dispatch({type: actionType.SET_UI_VALUES,payload: { [`${templateName.toLowerCase()}_popup`]: true }});
 }
export const deleteRow = (key, value, SubApplnName,props) => {
  let callDeleteApi = true;
  let { ID, uid } = value;
  let checkKey = ID ? 'ID' : uid ? 'uid' : null;
  if (uid) {
      callDeleteApi = false;
  }
  let subappname = SubApplnName;
  // props.deleteArrayValue(subappname, value, callDeleteApi, key);

  if (checkKey !== null) {
          let arr = props.componentState[key];
          arr.map((el, idx) => {
              if (el[checkKey] === value[checkKey]) {
                  arr.splice(idx, 1);
                  return arr;
              }
          })
          props.UpdateState(key, arr,true );

  }
}

export const onExpand = (key, value, isExpand,props) => {
  let { ID, uid } = value;
  let checkKey = ID ? 'ID' : uid ? 'uid' : null;
  if (checkKey !== null) {
          let arr = props.componentState[key];
          arr.map((el, idx) => {
              if (el[checkKey] === value[checkKey]) {
                  arr[idx]['isExpanded'] = isExpand.toString();
                  return arr;
              }
          })
          props.UpdateState( key, arr,true );
  }
}

export const onExpandV2 = (key, value, isExpand, props) => {
  let subAppName = props.tableActions.childComponent.childSubapplication
  let subappValue
  let subappKey
  subAppName.filter((subApp) => {
    if (value[subApp]) {
      subappKey = subApp
      subappValue = value[subApp]
    }
  })
  let { ID, uid } = subappValue;
  let checkKey = ID ? 'ID' : uid ? 'uid' : null;
  if (checkKey !== null) {
    let arr = props.componentState[key];
    arr.map((el, idx) => {
      if (el[subappKey][checkKey] === subappValue[checkKey]) {
        arr[idx]['isExpanded'] = isExpand.toString();
        return arr;
      }
    })
    props.UpdateState(key, arr, true);
  }
}

export const addRow = async (key, props) => {
  let uid = uuidv4();
  updateArrayValueOnState(key, { uid, isExpanded: "true"}, "true", props)
}

export const addRowWithMetaData = async (key, props) => {
  let uid = uuidv4();
  updateArrayValueOnState(key, { uid, isExpanded: "true"}, "true", props,true)
}

export const updateArrayValueOnStateV2 = async (key, value, expand, props, subappName, pagelevelValues, state, rowMetaDataNeeded) => {
  let arr = [];
  let subAppObj = {}
  value['isExpanded'] = expand.toString();
  let { ID, uid } = value;
  let checkKey = ID ? 'ID' : uid ? 'uid' : null;
  subAppObj = pagelevelValues
  subAppObj['isExpanded'] = expand.toString();

  if (props.componentState && props.componentState[key] !== undefined && Array.isArray(props.componentState[key])) {
    arr = props.componentState[key];
  }

  if ((checkKey === 'ID' && ID !== undefined) || (checkKey === 'uid' && uid !== undefined)) {
    let updated = false;
    await arr.map((el, idx) => {
      if (el[checkKey] === value[checkKey]) {
        let newObj = subAppObj
        if (subappName.length > 0) {
          subappName.map((val) => {
            subAppObj[val].isExpanded && delete subAppObj[val].isExpanded
            newObj = { ...newObj, ...subAppObj[val] }
          })
        } else {
          newObj = { ...newObj, ...subAppObj[subappName] }
        }
        arr[idx] = newObj;
        updated = true;
      }
    })
    if (updated === false) {
      arr.push(subAppObj);
    }
  } else {
    arr.push(subAppObj);
  }

  if (rowMetaDataNeeded) {
    arr = mergeAttributeListwithdata(arr, props.columnList);
  }
  props.UpdateState(key, arr, true)
}

export const tableMethods = {
  "addRow": addRow,
  "onExpand": onExpand,
  "deleteRow": deleteRow,
  "onChildSave": onClickSave,
}
const mergeResponseKeys=(subapps,...rest)=>{
  let obj={};
  subapps.forEach(subapp=>{
  let length=rest.length
      for(let i=0;i<length;i++){
      Object.keys(rest[i]).forEach(response=>{
          if(subapp==response){
              if(obj[subapp]===undefined){
              obj[subapp]=rest[i][response];
              }
              else{
                  obj[subapp]={...obj[subapp],...rest[i][response]}
              }
          }
      })
      }
  })
return obj;
}

const getAIRDistanceToCoast = async (props, state,Methodprops={},cancelAPIRef) => {
  try{
    let request = Object.create({});
    let property = {}
    let {actionName} = Methodprops;
    const cancelToken  = cancelAPIRef && cancelAPIRef.current[actionName] && cancelAPIRef.current[actionName].token ?cancelAPIRef.current[actionName].token : ''
    let {Latitude, Longitude} = state.pagelevelValues.LocationDetail
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    property = {
        Latitude : Latitude,
        Longitude : Longitude
    }
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName= props.pageInfo.getDTCEventName
    request["EventName"]=props.pageInfo.eventName
    request.Property = [property]
    let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request,{cancelToken})
    return response.data;
  }catch(err){
    if (Axios.isCancel(err)) {
      console.log('Request canceled', err.message);
    }else{
      console.log("Error in getAIRDistanceToCoast", err)
    }
  }
}

const getWildFireScore = async (props, state,Methodprops={},cancelAPIRef) => {
  try{
    let request = Object.create({});
    let property = {}
    let {actionName} =Methodprops;
    const cancelToken  = cancelAPIRef && cancelAPIRef.current[actionName] && cancelAPIRef.current[actionName].token ?cancelAPIRef.current[actionName].token :''
    let {StreetNumberAddress1 ,City,StateRegionCode,Zipcode1} = state.pagelevelValues && state.pagelevelValues.LocationDetail;
    let reduxState =store.getState()
    let SubmissionID = reduxState.UIFlowReducer.submissionID;
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    property = {
        StreetAddress : StreetNumberAddress1,
        City : City,
        State:StateRegionCode,
        ZipCode:Zipcode1,
        SubmissionID:SubmissionID
    }
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["LOB"] = props.pageInfo.LineOfBusiness;
    request["EventName"]=props.pageInfo.getWildfireEventName;
    request.Property = [property]
    let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request,{cancelToken})
    return response.data;
  } catch(err){
    console.log("Error in getWildFireScore",err)
  }
}

const invokeVeRiskAPI = async (props,state,Methodprops={},cancelAPIRef) =>{
  try{
  let request = {};
  const reduxState = store.getState()
  let {actionName} = Methodprops
  const cancelToken  =cancelAPIRef && cancelAPIRef.current[actionName] && cancelAPIRef.current[actionName].token ?cancelAPIRef.current[actionName].token :''
  request["ServiceRequestDetail"] = getServiceRequestDetail()
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request["TargetEventName"]=props.pageInfo.getVeRiskEventName
  request["EventName"]=props.pageInfo.eventName
  request.property=[{...state.pagelevelValues.LocationDetail,"ID":reduxState.UIFlowReducer.insuredID}]
  if (request.property[0].VeriskCalculatedRcv && request.property[0].VeriskValuationId) {
      delete request.property[0].VeriskValuationId
      delete request.property[0].VeriskCalculatedRcv
  }
  let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request,{cancelToken})
  return response.data
}catch(e){
  console.log("Error in invokeVeRiskAPI",e);
}
}

export const InvokeAPICall_IneligibleRiskAddressCheckV1 = async (Methodprops,value, props, state, component, tpo, pagelevelValues) => {
  // props.functions.UpdateState('loading',true,true)
  const reduxState = store.getState()
  let{objectId,objectVerId}=props.pageInfo;
  let SubApplicationList= props.pageInfo["subApplication"]["locationDetail"]
  let locationPageValues = pagelevelValues.LocationDetail
  let detailSubApp = []
  let summarySubApp = []
  let subappNameList = []
  let detailSummaryList = {}
  subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
  let transactionPrimaryObject = {}
  let request = Object.create({});
  request["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
  );
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName= props.pageInfo.changeEventName
  request.Location=[locationPageValues];
  let eligibleValues = {}
  await LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request)
      .then(async (response) => {
          let disableButton;
          if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                triggerToast('Address Eligibility Check failed')
                console.log('Response Failed')
                 return;
          }
          if(response.data.hasOwnProperty("IneligibilityMessage")){
              disableButton=true;
              eligibleValues = {LocationIneligible:"true",OverrideClearance:"false"}
              //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:true,OverrideClearance:false}});
          }
          else if(response.data.hasOwnProperty("OverrideMessage")){
              disableButton=false;
              eligibleValues = {LocationIneligible:"false",OverrideClearance:"true",BlockLocationSave:"true"}
              //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:true,BlockLocationSave:true}});
          }
          else{
              disableButton=false;
              eligibleValues = {LocationIneligible:"false",OverrideClearance:"false",BlockLocationSave:"false"}
              //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:false,BlockLocationSave:false}});
          }
          let distanceToCoast =props.pageInfo.hasOwnProperty("IsAIRDistanceToCoastNeeded") && props.pageInfo.IsAIRDistanceToCoastNeeded === true ?  await getAIRDistanceToCoast(props, {pagelevelValues}):{}
          let entitySubAppObject = await getEntitySubAppMappingV2({ subApplicationGroupList: [], objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subappName: subappNameList, state: 'CW'},'V2')
          let responseDTCTranslation = await EntitySubAppTranslationV2(distanceToCoast,  { "SubApplicationEntity": entitySubAppObject})
          let wildFireScore = props.pageInfo.hasOwnProperty("IsWildFireNeeded") && props.pageInfo.IsWildFireNeeded === true ? await getWildFireScore(props,{pagelevelValues}) : {};
          let wildFireTranslation = Object.keys(wildFireScore).length > 0 ? await EntitySubAppTranslationV2(wildFireScore,  { "SubApplicationEntity": entitySubAppObject}) : {}
          let getVeRiskDetails= props.pageInfo.hasOwnProperty("IsVeriskNeeded") && props.pageInfo.IsVeriskNeeded === true ?  await invokeVeRiskAPI(props,{pagelevelValues}):{}
          let responseVeriskTranslation = await EntitySubAppTranslationV2(getVeRiskDetails,  { "SubApplicationEntity": entitySubAppObject})
          let values =await mergeResponseKeys(subappNameList,responseVeriskTranslation,responseDTCTranslation,wildFireTranslation)
          values.LocationDetail = {...values.LocationDetail,...locationPageValues}
          Object.assign(transactionPrimaryObject,{...values,...eligibleValues})
          props.functions.UpdateState("disableButton",disableButton, false)
          props.functions.UpdateState("pagelevelValues",values,false)
          props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
        })
        .catch((err) => {
          triggerToast(err.message)
          console.log(err);
        });
        let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName:subappNameList},{transactionPrimaryObject:{...transactionPrimaryObject,...eligibleValues}})
        props.functions.UpdateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},false)
      //   props.functions.UpdateState('loading',false,true)
}

class ConstructData{
  constructor(initialData={}){
    this.constructedData = {...initialData}
  }

  get data(){
    return {...this.constructedData}
  }

  set data(newdata){
    this.constructedData = {...this.constructedData ,...newdata}
  }

}




async function dynamicUpdateResponse(Methodprops={},value, props, state, component, transactionPrimaryObject, pagelevelValues){
  try{
    // const reduxState = store.getState()
    if("UpdateResponse" in Methodprops){
      let { UpdateResponse } = Methodprops
      if("SessionState" in UpdateResponse && UpdateResponse.SessionState.length>0)
      {
        let reduxUpdate = {}
         this.constructedData =await dynamicObjectConstruction(UpdateResponse.SessionState,  this.response, reduxUpdate,  'response')
         store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxUpdate })
      }
      if('ApplicationState' in UpdateResponse && UpdateResponse.ApplicationState.length > 0){
        let reduxUpdate = {}
        this.constructedData = await dynamicObjectConstruction(UpdateResponse.ApplicationState, this.response, reduxUpdate, 'response')
        store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: reduxUpdate })
      }
      if('CustomSessionUpdate' in UpdateResponse  && UpdateResponse.CustomSessionUpdate.length > 0){
        let reduxUpdate = {}
        reduxUpdate = await dynamicObjectConstruction(UpdateResponse.CustomSessionUpdate, this.response, reduxUpdate, "custom");
        store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxUpdate })
      }
      }
  }
  catch(e){
    console.error("Error in dynamicConstructResponse", e.message)
  }
}

 function dynamicConstructRequest(Methodprops,value, props, state, component, transactionPrimaryObject, pagelevelValues){
  try{
  const reduxState = store.getState()
  if("ConstructRequest" in Methodprops){
    let { ConstructRequest } = Methodprops
    if("SessionState" in ConstructRequest && ConstructRequest.SessionState.length>0)
    {
      let {UIFlowReducer, metadataReducer}=reduxState
       this.constructedData = dynamicObjectConstruction(ConstructRequest.SessionState, {...metadataReducer, ...UIFlowReducer}, this.constructedData, 'request')
    }
    if('localState' in ConstructRequest && ConstructRequest.localState.length > 0) {
      this.constructedData =  dynamicObjectConstruction(ConstructRequest.localState, {...props, ...state}, this.constructedData, 'request')
    }
    if('ApplicationState' in ConstructRequest && ConstructRequest.ApplicationState.length > 0){
      let {metadataReducer}=reduxState
      this.constructedData =  dynamicObjectConstruction(ConstructRequest.ApplicationState, metadataReducer, this.constructedData, 'request')
    }
    }
  }
  catch(e){
    console.error("Error in dynamicConstructRequest", e.message)
  }

}

async function dynamicConstructData(Methodprops,value, props, state, component, transactionPrimaryObject, pagelevelValues){
  const reduxState = store.getState()
  if("ConstructData" in Methodprops){
      let { ConstructData } = Methodprops
          if("SessionState" in ConstructData && ConstructData.SessionState.length>0)
          {
              let {UIFlowReducer}=reduxState
              this.constructedData =await dynamicObjectConstruction(ConstructData.SessionState, UIFlowReducer, this.constructedData, 'custom')
          }
          if('localState' in ConstructData && ConstructData.localState.length > 0) {
              this.constructedData = await dynamicObjectConstruction(ConstructData.localState, {...props.componentProps, ...props.componentState}, this.constructedData, 'custom')
          }
          if('ApplicationState' in ConstructData && ConstructData.ApplicationState.length > 0){
              let {metadataReducer}=reduxState
              this.constructedData = await dynamicObjectConstruction(ConstructData.ApplicationState, metadataReducer, this.constructedData, 'custom')
          }
    }
}


function constructDataWrapper(func){
  let Obj = new ConstructData()
  return async function(){
     await dynamicConstructData.call(Obj, ...arguments)
     await func.call(Obj, ...arguments)
  }
}

async function constructDataFunc(func, initialValue, ...args) {
  try{

    const wrappeddata = new ConstructData(initialValue)
    await func.apply(wrappeddata, args)
    return wrappeddata.data;
  }
  catch(e){
    console.log("Error in constructDataFunc", e)
  }
 }





const dynamicUpdateStateFunc = function(Methodprops,value, props, state, component, transactionPrimaryObject){
  try{
    let {stateUpdate=true, parentUpdate} = Methodprops
    let stateFunc =  props.functions.UpdateState
    let parentFunc = props.componentProps.parentState && props.componentProps.parentState.UpdateState
    Object.entries(this.constructedData).forEach(([key, value], i) => {
            if(stateUpdate){
                stateFunc(key, value, false)
              }
              if(parentUpdate){
                  parentFunc(key, value, (this.constructedData.length - 1) === i)
              }
      })
  }
  catch(e){
      console.error("Error in Dynamic UpdateState Method", e)
  }
}


export const commonChangeMethod = async(Actions,value, props, state, component, transactionPrimaryObject, pagelevelValues,cancelAPIRef) => {
  let {UpdateState} = props && props.functions


  const {utilfunction} = props.pageInfo

  const UtilFunctions = {
      //temporary code: Util method used: cleanup later
      ...utilfunction,
      IneligibleRiskAddressCheck: InvokeAPICall_IneligibleRiskAddressCheckV1,
      dynamicUpdateState: constructDataWrapper(dynamicUpdateStateFunc),
      getAPI,
      getAPIMultiple,
      IneligibleAddressRequest,
      DTCRequest,
      WildfireRequest,
      VeRiskRequest,
      LocationSubappListConstruction,
      getentity,
      entitysubapptranslation,
      mergevalues,
      setLocationEligiblityValues,
      wildfiresetValues,
      InvokeAPICall_IneligibleRiskAddressCheckV2
  }

  const {Action={}} = component;
  let loaderNeeded=true
  if("isLoaderNeeded" in props.pageInfo && props.pageInfo.isLoaderNeeded===false){
        loaderNeeded=false
  }
  if("isLoaderNeeded" in component.Action){
        loaderNeeded=component.Action.isLoaderNeeded
  }
  // const loaderNeeded = !("LoaderNeeded" in Action) || Action.LoaderNeeded==='Y' ? true: false;

  const executeFunctions = async(functions, order) => {
      try{
          UpdateState("loading", loaderNeeded, true)
          const ActionsData = new ConstructData()
          for (const Methodprops of order) {
              const {actionName}  = Methodprops
              if (functions[actionName] && typeof functions[actionName] === 'function') {
                  await functions[actionName].call(ActionsData,Methodprops,value, props, state, component, transactionPrimaryObject, pagelevelValues, UtilFunctions,cancelAPIRef);
              } else {
                  console.error(`Function ${actionName} not found in commonChangeMethod or not a function`);
              }
          }
      }
      catch(e){
          console.log("Error in commonChangeMethod executeFunctions", e)
      }
      finally {
          UpdateState("loading", false, true)
      }
  };
 await executeFunctions(UtilFunctions, Actions);
}

export const handleInputChangeV3 = (value, component, isDynamicTable, datatableRowindex, callback, state,props, argObj) => {
  try{
  let keys = Object.keys(value);
  let attributeName = keys[0];
  let attributeValue;
  if(Array.isArray(value[attributeName])===true && value[attributeName].length>0){
    attributeValue = value[attributeName];
  }
  else{
    attributeValue = value[attributeName].toString();
  }
  let subappName = component.SubApplicationName;
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);
  if (!pagelevelValues[subappName]) {
    pagelevelValues[subappName] = {};
  }
  if (!transactionPrimaryObject[subappName]) {
    transactionPrimaryObject[subappName] = {};
  }
  //pagelevelValues[subappName][attributeName] = attributeValue;
   pagelevelValues[subappName] = {...pagelevelValues[subappName], [attributeName]:attributeValue};
  //transactionPrimaryObject[subappName][attributeName] = attributeValue;
   transactionPrimaryObject[subappName] = {...transactionPrimaryObject[subappName], [attributeName]:attributeValue};

  if(props && props.componentState && typeof props.componentState.IteratorIndex === 'number'){
    state[argObj.dataListKey].forEach((data, index)=>{
     if(index === props.componentState.IteratorIndex){
       let subappObj = data[component.SubApplicationName] || {}
       let dataObj = {[component.SubApplicationName]: Object.assign(subappObj,value)}
       Object.assign(data,dataObj);
     }
   })
  }
  deleteDependentAttribute(component,pagelevelValues,transactionPrimaryObject)
  return { pagelevelValues, transactionPrimaryObject};
}
catch(exception){
  console.log("Exception in handleInputChangev2", exception)
}
}

async function RequestConstruction(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){
  try{
  if(Methodprops.RequestConstruction && Methodprops.RequestConstruction==="Y")
    {
        let rootlevelNeed =false;
        if(Methodprops.IsRootLevelNeeded && Methodprops.IsRootLevelNeeded==="Y" ){
            rootlevelNeed=true;
            let r =await constructRequestValuesV2(props,pagelevelValues,this.getrequest,state,true,false,rootlevelNeed);
            this.getrequest ={...this.getrequest,...r}
        }else{
            let r =await constructRequestValues(props,pagelevelValues,this.getrequest,state,true,false);
            this.getrequest ={...this.getrequest,...r}
        }
}
  }
  catch(e){
    console.error("Error in RequestConstruction", e.message)
  }
}


function setFieldLoader(Methodprops, update, props){
  if(Methodprops.fieldLoader){
    const key = Methodprops.fieldLoader + '_loading'
    props.functions.UpdateState(key, update, true)
  }
}


   export async function getAPI(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
     try {
        // setLoader(Methodprops, props)
        const ApiData =  new ConstructData(this.data || {})
         let {requestInfo={}} = Methodprops
         let EventKeys = ["EventName", "TargetEventName", "EventAction"]
         let getrequest = {}
         getrequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
         getrequest["OwnerId"] = sessionStorage.getItem("OwnerID");
         if("eventNames" in requestInfo){
             for(const key of EventKeys){
                 if(key in requestInfo.eventNames) getrequest[key] = requestInfo.eventNames[key]
              }
          }
       await RequestConstruction.call({getrequest},...arguments)
       const request = constructDataFunc(dynamicConstructRequest, getrequest, requestInfo, value, props, state, component, transactionPrimaryObject,pagelevelValues)
       getrequest = {...getrequest, ...request}
       if(Methodprops.onStartMethod && UtilFunctions[Methodprops.onStartMethod]){
       const request = await constructDataFunc(UtilFunctions[Methodprops.onStartMethod], {}, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
        getrequest = {...getrequest, ...request}
       }
       setFieldLoader(Methodprops, true, props)
        let defaultURL = 'core/invokeWorkflowEvent'
         let apiURL = `${requestInfo.eventURL || defaultURL}` + `/${requestInfo.eventVersion || ''}`;
         if(Methodprops.apiType === 'promise'){
              return AxiosInstance.post(apiURL, getrequest);
         }
         let response = await AxiosInstance.post(apiURL, getrequest);
          if(Methodprops.hasOwnProperty("toaster")){
              showtoaster(Methodprops.toaster, response)
          }
          setFieldLoader(Methodprops, false, props)
           ApiData.data = {response: response.data}
          if(Methodprops.responseInfo.entitysubapptranslation === 'Y'){
           await entitysubapptranslation.call(ApiData, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
          }
          if(Methodprops.onEndMethod && UtilFunctions[Methodprops.onEndMethod]){
            await UtilFunctions[Methodprops.onEndMethod].call(ApiData, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
            }
        this.data && (this.data = {...ApiData.data})
      } catch (error) {
          console.error("Error in getAPI method", error.message)
      }
}


export async function getAPIMultiple(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
  try{
    let {promiseType, PromiseActions=[], responseInfo={}} = Methodprops

    let getAPIMultipleData  = new ConstructData(this.data || {})
    let responseData = []


    let Promises = PromiseActions.map((Action)=> {
          if(Action.actionName && UtilFunctions[Action.actionName]){
          const apiType='promise';
           return UtilFunctions[Action.actionName].call(getAPIMultipleData, {...Action, apiType}, value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
          }
    })


    if(promiseType.toLowerCase() ==='all'){
      responseData = await Promise.all(Promises)
    }
    else if(promiseType.toLowerCase() === 'allsettled'){
      responseData = await Promise.allSettled(Promises)
    }
    for(let i=0; i<responseData.length; i++){
      const response = responseData[i]
        if(response.status === 'fulfilled' && response.value){
            const actionData = PromiseActions[i]
            const {responseInfo={}, actionName} = actionData
            let responseData = response.value.data
            if(responseData && 'ResponseStatus' in responseData && responseData.ResponseStatus.toLowerCase() === 'failed'){
              console.log('Response Failed')
              continue;
            }
            let singleApiData = new ConstructData({response: responseData})
            if(actionName==='getentity'){
              getAPIMultipleData.data = {SubApplicationEntity: response.value}
              continue;
            }

           await dynamicUpdateResponse.call(singleApiData,actionData.responseInfo, value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)

            if(actionData.onEndMethod && UtilFunctions[actionData.onEndMethod]){
             await UtilFunctions[actionData.onEndMethod].call(singleApiData, actionData,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
             }

            if(responseInfo.construct && responseInfo.construct==='N') continue;


            const newData = getAPIMultipleData.data

            if(singleApiData.data.transactionPrimaryObject){
              if(!newData.transactionPrimaryObject){
                getAPIMultipleData.data = {transactionPrimaryObject: {}}
              }
              getAPIMultipleData.data  = {transactionPrimaryObject: {...getAPIMultipleData.data.transactionPrimaryObject, ...singleApiData.data.transactionPrimaryObject}}
            }

            if(!newData.responseList){
              getAPIMultipleData.data = {responseList: []}
            }

            getAPIMultipleData.constructedData.responseList.push(singleApiData.data.response)
            }
    }

    this.data = {...getAPIMultipleData.data}
  }
  catch(e){
    console.error(`Error in GetAPI Multiple`, e.message)
  }

}


function setLocationEligiblityValues(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){
  try{
  let {response} = this.data
 let disableButton;
 let eligibleValues = {}
  if(response.hasOwnProperty("IneligibilityMessage")){
   disableButton=true;
   eligibleValues = {LocationIneligible:"true",OverrideClearance:"false"}
 }
 else if(response.hasOwnProperty("OverrideMessage")){
   disableButton=false;
   eligibleValues = {LocationIneligible:"false",OverrideClearance:"true",BlockLocationSave:"true"}
    }
 else{
   disableButton=false;
   eligibleValues = {LocationIneligible:"false",OverrideClearance:"false",BlockLocationSave:"false"}
    }

const tpo = eligibleValues

this.data = {transactionPrimaryObject: tpo}

 props.functions.UpdateState("disableButton",disableButton, false)

  }
  catch(e){
    console.log('Error in setLocationEligiblityValues', e)
  }
}


async function IneligibleAddressRequest(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){
    this.data={Location: [pagelevelValues.LocationDetail]};
}

async function DTCRequest(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){
  const request = {}
  const {Latitude, Longitude} = pagelevelValues.LocationDetail
 const property = {
      Latitude : Latitude,
      Longitude : Longitude
  }
  request.Property = [property]
  this.data = {...request}
}


async function WildfireRequest(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){
  const request = {}
  const redux =store.getState()
  const {UIFlowReducer} =  redux
  let property = {}
  let {StreetNumberAddress1 ,City,StateRegionCode,Zipcode1} = pagelevelValues.LocationDetail;
  property = {
      StreetAddress : StreetNumberAddress1,
      City : City,
      State:StateRegionCode,
      ZipCode:Zipcode1,
      SubmissionID: UIFlowReducer.submissionID
  }
  request.Property = [property]
  this.data = {...request}
}

async function VeRiskRequest(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues){

  let request = {};
  const reduxState = store.getState()
  request.property=[{...pagelevelValues.LocationDetail,"ID":reduxState.UIFlowReducer.insuredID}]
  if (request.property[0].VeriskCalculatedRcv && request.property[0].VeriskValuationId) {
      delete request.property[0].VeriskValuationId
      delete request.property[0].VeriskCalculatedRcv
  }
  this.data={...request}
}




function LocationSubappListConstruction(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
  let SubApplicationList= props.pageInfo["subApplication"]["locationDetail"]
  let detailSubApp = []
  let summarySubApp = []
  let subappNameList = []
  let detailSummaryList = {}
  subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
  this.data = {subappName: subappNameList}
}



async function getentity(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
  try{
    let state=Methodprops.region || 'CW';
    let {objectId,objectVerId,subappName}=Methodprops
    let subappInfo = {objectId, objectVerId, subappName, state}
    if(Methodprops.onStartMethod && UtilFunctions[Methodprops.onStartMethod]){

      const info = await constructDataFunc(UtilFunctions[Methodprops.onStartMethod], subappInfo, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)
        subappInfo = {...subappInfo, ...info}
     }
    if(Methodprops.apiType === 'promise'){
      return getEntitySubAppMappingV2(subappInfo, "V2");
    }
  const getEntity = await getEntitySubAppMappingV2(subappInfo, "V2")
  this.data = {SubApplicationEntity: getEntity}
  }catch(innerError){
    console.error('Error in getEntity', innerError.message)
  }
}


async function entitysubapptranslation(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
try{
  let {SubApplicationEntity, response, responseList} = this.data;
  if(Methodprops.type==='list' && responseList && responseList.length>0){
   const entityPromise = responseList.map((response)=>{
     return EntitySubAppTranslationV3(response,  { "SubApplicationEntity": SubApplicationEntity})
    }
  )
 const translatedResponseList =await Promise.all(entityPromise)
 this.data = {translatedResponseList}
 return;
}

if(!response || !SubApplicationEntity) return;

  let responsefromEntity = await EntitySubAppTranslationV3(response,  { "SubApplicationEntity": SubApplicationEntity})
  if(responsefromEntity){
    this.data = {...responsefromEntity}
  }
}
catch(e){
    console.error('Error in entitysubapptranslation', e.message)
}
}

async function mergevalues(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
 const {translatedResponseList, transactionPrimaryObject:FlowTPO ={} } = this.data
 let{objectId,objectVerId}=props.pageInfo;
 const translatedResponse = translatedResponseList.map((data)=> ({...data.translatedResponse}))

 const mergevalues =await constructDataFunc(LocationSubappListConstruction, {}, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)

 let subappName = mergevalues.subappName

 let values;
 if(translatedResponse && translatedResponse.length>0){
  values =await mergeResponseKeys(subappName,...translatedResponse)
 }

 let locationPageValues = pagelevelValues.LocationDetail
 values.LocationDetail = {...values.LocationDetail,...locationPageValues}
 Object.assign(transactionPrimaryObject,{...values, ...FlowTPO})
 props.functions.UpdateState("pagelevelValues",values,false)
 props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
 let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName},{transactionPrimaryObject:{...transactionPrimaryObject, ...FlowTPO}})
 props.functions.UpdateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},true)
}


async function wildfiresetValues(Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions){
try{
  const {translatedResponse} = this.data

  const mergevalues =await constructDataFunc(LocationSubappListConstruction, {}, Methodprops,value, props, state, component, transactionPrimaryObject,pagelevelValues, UtilFunctions)

  let subappName = mergevalues.subappName

  const UpdateCallBack = props.functions.UpdateCallBack

  UpdateCallBack && UpdateCallBack(function(state) {
    try{
      let values = mergeResponseKeys(subappName, state.pagelevelValues, translatedResponse)
      return {pagelevelValues: {...state.pagelevelValues, ...values}}
    }
    catch(e){
      console.error("Error in wildfiresetValues UpdateCallBack function", e.message)
    }
  })

}
catch(e){
  console.error("Error in wildfiresetValues", e.message)
}
}



 async function IneligibleRiskAddressCheck(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef){
try{
  let {actionName} =Methodprops;
  const cancelToken  = cancelAPIRef && cancelAPIRef.current[actionName] && cancelAPIRef.current[actionName].token ?cancelAPIRef.current[actionName].token :''
  let locationPageValues = pagelevelValues.LocationDetail
  let request = Object.create({});
  request["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
  );
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName= props.pageInfo.changeEventName
  request.Location=[locationPageValues];
  let eligibleValues = {}
  let disableButton;
  const response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request,{cancelToken});
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    triggerToast('Address Eligibility Check failed')
    console.log('Response Failed')
     return;
}
if(response.data.hasOwnProperty("IneligibilityMessage")){
  disableButton=true;
  eligibleValues = {LocationIneligible:"true",OverrideClearance:"false"}
  //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:true,OverrideClearance:false}});
}
else if(response.data.hasOwnProperty("OverrideMessage")){
  disableButton=false;
  eligibleValues = {LocationIneligible:"false",OverrideClearance:"true",BlockLocationSave:"true"}
  //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:true,BlockLocationSave:true}});
}
else{
  disableButton=false;
  eligibleValues = {LocationIneligible:"false",OverrideClearance:"false",BlockLocationSave:"false"}
  //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:false,BlockLocationSave:false}});
}
props.functions.UpdateState("disableButton",disableButton, true)
return eligibleValues
}
catch(e){
  if (Axios.isCancel(e)) {
    console.log('Request canceled', e.message);
  } else {
  console.error("Error in IneligibleRiskAddressCheck",e.response , e.response.data , e.message)

  }
}
}


async function verriskIneligbleCheck(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef){
  try{

    props.functions.UpdateState('verisk_loading', true, true)
    const IneligibleAddressPromise  = IneligibleRiskAddressCheck(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef)

    const veRiskPromise = Methodprops.VeRiskNeeded==='Y' ? invokeVeRiskAPI(props,{pagelevelValues},Methodprops,cancelAPIRef): undefined;

    const MapRiskDTCPromise = Methodprops.DTCNeeded==='Y' ? SetMapRiskDTCValues.call(this, Methodprops,value, props, state, component, tpo, pagelevelValues, UtilFunctions,cancelAPIRef ) : undefined
    let{objectId,objectVerId}=props.pageInfo;

    let verRiskResponse={}
    let eligibleValues={}
    let DTCResponse = {}

    const [res1, res2, res3] =await Promise.allSettled([IneligibleAddressPromise, veRiskPromise, MapRiskDTCPromise])
    props.functions.UpdateState('verisk_loading', false, true)
    if(res1.status === 'fulfilled' && res1.value){

    let responseData = res1.value

    if(!responseData)return;

    eligibleValues=responseData
  }

  if(res2 && res2.status === 'fulfilled' && res2.value){

    let responseData = res2.value

    if(responseData && 'ResponseStatus' in responseData && responseData.ResponseStatus.toLowerCase() !== 'failed'){

      verRiskResponse =  responseData
    }
  }

  if(res3 && res3.status === 'fulfilled' && res3.value){
    let responseData = res3.value
    DTCResponse =  responseData
  }



  let responseVeriskTranslation = await EntitySubAppTranslationV2(verRiskResponse,  { "SubApplicationEntity": this.entitySubAppObject})


  const UpdateCallBack = props.functions.UpdateCallBack


  const subappNameList = this.subappNameList


  UpdateCallBack && UpdateCallBack(function(state) {
    try{
      let pagevalues = mergeResponseKeys(subappNameList, state.pagelevelValues, responseVeriskTranslation, DTCResponse)
      let TPO = mergeResponseKeys(subappNameList, state.transactionPrimaryObject, responseVeriskTranslation, DTCResponse)
      return {pagelevelValues: {...state.pagelevelValues, ...pagevalues}, transactionPrimaryObject: {...state.transactionPrimaryObject,...TPO,...eligibleValues}, callbackNeeded: true}
    }
    catch(e){
      console.error("Error in wildfiresetValues UpdateCallBack function", e.message)
    }
  })


  // let LocationDetail = {...pagelevelValues.LocationDetail, ...responseVeriskTranslation.LocationDetail}
  // let pagevalues = mergeResponseKeys(subappNameList, state.pagelevelValues, responseVeriskTranslation, DTCResponse)

  // let TPO  = {...pagevalues, LocationDetail, ...eligibleValues}


  // let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName:this.subappNameList},{transactionPrimaryObject:{...TPO}})
  // props.functions.UpdateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},true)


  }
  catch(e){
    console.log("Error in verriskIneligbleCheck",e)
  }
}

async function SetMapRiskDTCValues(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef){
 try{
  props.functions.UpdateState('mapriskDTC_loading', true, true)
 let response = await getAIRDistanceToCoast(props, {pagelevelValues},Methodprops,cancelAPIRef)

 let translatedResponse = await EntitySubAppTranslationV2(response,  { "SubApplicationEntity": this.entitySubAppObject})

 props.functions.UpdateState('mapriskDTC_loading', false, true)
 return translatedResponse;
//   const UpdateCallBack = props.functions.UpdateCallBack

//  const subappNameList = this.subappNameList

  // UpdateCallBack && UpdateCallBack(function(state) {
  //   try{
  //     let pagevalues = mergeResponseKeys(subappNameList, state.pagelevelValues, translatedResponse)
  //     let TPO = mergeResponseKeys(subappNameList, state.transactionPrimaryObject, translatedResponse)
  //     return {pagelevelValues: {...state.pagelevelValues, ...pagevalues}, transactionPrimaryObject: {...state.transactionPrimaryObject,...TPO}, mapriskDTC_loading: false}
  //   }
  //   catch(e){
  //     console.error("Error in wildfiresetValues UpdateCallBack function", e.message)
  //   }
  // })
 }
 catch(e){
   console.error("Error in SetMapRiskDTCValues",e.message)
 }
}


async function SetWildFireValues(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef){
  try{
  props.functions.UpdateState('wildfire_loading', true, true)
  let response = await getWildFireScore(props,{pagelevelValues},Methodprops,cancelAPIRef)

  if(response.Building&& Object.keys(response.Building).length){
  let translatedResponse = await EntitySubAppTranslationV2(response,  { "SubApplicationEntity": this.entitySubAppObject})

   const UpdateCallBack = props.functions.UpdateCallBack

   const subappNameList= this.subappNameList

   UpdateCallBack && UpdateCallBack(function(state) {
     try{
      let pagevalues = mergeResponseKeys(subappNameList, state.pagelevelValues, translatedResponse)
      let TPO = mergeResponseKeys(subappNameList, state.transactionPrimaryObject, translatedResponse)
       return {pagelevelValues: {...state.pagelevelValues, ...pagevalues}, transactionPrimaryObject: {...state.transactionPrimaryObject,...TPO}, wildfire_loading: false}
     }
     catch(e){
       console.error("Error in SetWildFireValues UpdateCallBack function", e.message)
     }
   })
  }
}
  catch(e){
    console.log("Error in SetWildFireValues",e.message)
  }
 }


 async function InvokeAPICall_IneligibleRiskAddressCheckV2(Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef){
  try{
  // props.functions.UpdateState('loading',true,true)
  const reduxState = store.getState()
  let{objectId,objectVerId}=props.pageInfo;
  let {Action: {Actions}} = component
  let {actionName} = Methodprops;

  if(cancelAPIRef && cancelAPIRef.current && cancelAPIRef.current[actionName]){
    cancelAPIRef.current[actionName].cancel("Operation canceled due to new input.")
  }

  cancelAPIRef.current ={
    [actionName]: Axios.CancelToken.source()
  }

  let SubApplicationList= props.pageInfo["subApplication"]["locationDetail"]
  let LocationDetail = pagelevelValues.LocationDetail
  let detailSubApp = []
  let summarySubApp = []
  let subappNameList = []
  let detailSummaryList = {}
  const UpdateCallBack = props.functions.UpdateCallBack
  UpdateCallBack && UpdateCallBack(function(state) {
      return {pagelevelValues: {LocationDetail}, transactionPrimaryObject: {LocationDetail}}
  })
  subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
  const entitySubAppObject = (props.componentState.pageMetadata.ApplicationDetail && props.componentState.pageMetadata.ApplicationDetail.SubApplicationEntity) || {}
  // let entitySubAppObject = await getEntitySubAppMappingV2({ subApplicationGroupList: [], objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subappName: subappNameList, state: 'CW'},'V2')


  const verRiskPromise = verriskIneligbleCheck.call({ entitySubAppObject, subappNameList}, Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef)

  // const MapRiskDTCPromise = SetMapRiskDTCValues.call({ entitySubAppObject, subappNameList}, Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef)

  const wildFirePromise=Methodprops.WildfireNeeded==='Y' && SetWildFireValues.call({ entitySubAppObject, subappNameList}, Methodprops,value, props, state, component, tpo, pagelevelValues,UtilFunctions,cancelAPIRef)

}
catch(e){
  console.error("Error in SetWildFireValues",e)
}
}
